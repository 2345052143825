import * as React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Grid, Cell } from 'styled-css-grid';
import BackgroundImage from 'gatsby-background-image';
import ReactMarkdown from 'react-markdown';
import * as T from '../Typography';
import * as S from './styles';
import { useMobileBreakpoint } from '../../utils/breakpoints';

export interface IProps {
  breakpoints: any;
  currentBreakpoint: string;
  quote: string;
  cite: string;
  image: any;
}

const Citation = (props: IProps) => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);

  return (
    <S.Citation>
      <Grid columns={24}>
        <Cell width={isMobile ? 22 : 24} left={2}>
          <Grid columns={12}>
            <Cell width={isMobile ? 12 : 9} left={isMobile ? 1 : 4}>
              <S.Blockquote as={isMobile ? T.H5 : T.H3}>
                <S.BackgroundImage>
                  <BackgroundImage
                    Tag="div"
                    fluid={props.image && props.image.childImageSharp.fluid}
                    className="backgroundImage"
                    style={{
                      // https://www.gatsbyjs.org/packages/gatsby-background-image/
                      position: 'static',
                      backgroundSize: isMobile ? 'contain' : 'cover',
                      backgroundPosition: isMobile
                        ? 'right var(--x2-space)'
                        : 'right center',
                      backgroundRepeat: 'no-repeat'
                    }}
                    classId=""
                  />
                </S.BackgroundImage>
                <blockquote>
                  <ReactMarkdown source={props.quote} escapeHtml={false} />
                  <S.Cite>{props.cite}</S.Cite>
                </blockquote>
              </S.Blockquote>
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </S.Citation>
  );
};

export default withBreakpoints(Citation);
