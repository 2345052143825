import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Column from '../components/Column';
import Media from '../components/Media';
// import CorporateLogo from '../components/CorporateLogo';
import Citation from '../components/Citation';
import BoxImage from '../components/BoxImage';
import Quote from '../components/Quote';
import Background from '../components/Background';
import Head from '../components/Head';

const CorporatePage = ({ data }) => {
  const corporate = data.corporate.frontmatter;

  return (
    <Layout>
      <Background />
      <Head title={corporate.title} description={corporate.description} />
      <Header {...corporate.header} />
      <Column overlappedBottom={true} hasLine={true} {...corporate.column} />
      <Media overlapTop={true} {...corporate.media} />
      <Quote {...corporate.quote} />
      {/*<CorporateLogo {...corporate.corporateLogo} />*/}
      <Citation {...corporate.citation} />
      <BoxImage {...corporate.boxImage2} />
      <Quote {...corporate.quoteCTA} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query SiteCorporatePageQuery {
    corporate: markdownRemark(
      frontmatter: { templateKey: { eq: "z1-corporate" } }
    ) {
      frontmatter {
        title
        description
        header {
          headline
          straplines
          address
        }
        column {
          backgroundColor
          color
          label
          headline
          text1
          text2
        }
        media {
          image {
            childImageSharp {
              fluid(quality: 85, maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          label
          headline
          strapline
          text
        }
        quote {
          label
          text
        }
        corporateLogo {
          logos {
            image {
              childImageSharp {
                fluid(quality: 85, maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        citation {
          quote
          cite
          image {
            childImageSharp {
              fluid(quality: 85, maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        boxImage2 {
          boxes2 {
            image {
              childImageSharp {
                fluid(quality: 85, maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            headline
            button {
              label
              url
            }
          }
        }
        quoteCTA {
          label
          text
          color
          backgroundColor
          button {
            label
            url
          }
        }
      }
    }
  }
`;

export default CorporatePage;
