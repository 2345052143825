import styled from 'styled-components';
import { Section }  from '../Layout/styles';
import Quote from '../../images/bgQuote.svg';
import * as T from '../Typography'

export const Citation = styled(Section)``;

export const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 30%;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    left: 40%;
  }
`;

export const Blockquote = styled.div`
  background-image: url(${Quote});
  background-repeat: no-repeat;
  background-position: left var(--x4-space);
  background-size: 35px 26px;
  padding: var(--x5-space) var(--x3-space);
  position: relative;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    background-size: 70px 53px;
    padding: var(--x5-space) var(--x6-space);
  }

  /* stylelint-disable */
  blockquote {
    position: relative;
  }
  /* stylelint-enable */
`;

export const Cite = styled(T.SmallBodyCopy)`
  color: var(--grey);
  margin-top: var(--x2-space);
`
